/*
Theme Name: DragonCourt - MULTIPURPOSE HTML5 Template.
Author: Slidesigma
Author URL: https://www.templatemonster.com/vendors/slidesigmathemes/
Version: 1.0.0
*/
@media (max-width: 1500px) {
	.blog-detail .comment-respond{
		padding: 20px;
	}
	.rs-retailers ul li{
		width: 33.33%;
	}
	.bottom-navigation .navigation-btm{
		padding: 0 15px;
	}
	.our_articles .post>.post-wrapper>.post-img{
		padding: 40px;
		padding-bottom: 0;
	}
	.our_articles .post>.post-wrapper .post-meta{
		padding: 40px;
	}
	.team-sec ul>.team-item{
		width: 33.33%;
	}
	.aboutsec,
	.recipe-sec,
	.two-side-fix,
	.reservation-page{
		height: auto;
	}
}
@media (max-width: 1200px) {
	.subheader .subheader-bottom,
	.pagination-wrap,
	.custom-container{
		padding: 0 15px;
	}
	.subheader .floating-social{
		right: 15px;
	}
	.full-width-menu .no-gutters>[class*=col-]:first-child .menu-wrapper .top-sec,
	.full-width-menu .no-gutters>[class*=col-]:first-child .menu-wrapper .bottm-sec,
	.full-width-menu .no-gutters>[class*=col-]:nth-child(4) .menu-wrapper .top-sec,
	.full-width-menu .no-gutters>[class*=col-]:nth-child(4) .menu-wrapper .bottm-sec{
	    padding-left: 15px;
	}
	.full-width-menu .no-gutters>[class*=col-]:last-child .menu-wrapper .top-sec,
	.full-width-menu .no-gutters>[class*=col-]:last-child .menu-wrapper .bottm-sec,
	.full-width-menu .no-gutters>[class*=col-]:nth-child(3) .menu-wrapper .top-sec,
	.full-width-menu .no-gutters>[class*=col-]:nth-child(3) .menu-wrapper .bottm-sec{
	    padding-right: 15px;
	}
	.mb-lg-20 {
		margin-bottom: 20px;
	}
	.mb-lg-30 {
		margin-bottom: 30px;
	}
	.mb-lg-40 {
		margin-bottom: 40px;
	}
	.mb-lg-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 992px) {
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.first-link,
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.large-nav,
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.small-nav,
	.navigation-main .nav-div .nav-content .menu-slider-wrap,
	.navigation-main .nav-div .nav-content .center-logo-wrap,
	.navigation-main .nav-div .nav-content>.row>div.col-lg-2,
	.search-box-input .input-group-append,
	.our-history-sec>div>.row:after,
	.menu-testimonial,
	.header-style-1,
	.hide-in-mob,	
	.close-nav{
		display: none;
	}
	.hide-in-desk,
	.mobile-header{
		display: block;
	}
	.navigation-main .nav-div .nav-content .search-box-input,
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.first-link{
		position: relative;
		top: 0;
		width: 100%;
		transform: none;
		border: 0;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.first-link{
		width: 50%;
		border-right: 1px solid rgba(61,50,44,.2);
		border-bottom: 1px solid rgba(61,50,44,.2);
	}
	.navigation-main.active .nav-div .nav-content{
		height: auto;
		overflow-y: auto;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li{
		width: 50%;
    	height: 60px;
    	border-right: 0px solid rgba(61,50,44,.2);
    	border-left: 1px solid rgba(61,50,44,.2);
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li>a{
		justify-content: flex-start;
		padding-left: 28px;
	}
	.navigation-main .nav-div .nav-content>.row>div{
		padding-top: 0;
	}
	.navigation-main{
		margin-top: 68px;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.active>a:after{
		margin: 5px auto 0 30px;
	}
	/*Navigation 2*/
	.bottom-navigation .navigation-btm .main-menu-nav .mobile-logo{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 150px;
	    height: 90px;
	    margin: 0 auto 20px;
	}
	.bottom-navigation .navigation-btm .btm-hamburger{
		display: flex;
	}
	.bottom-navigation .navigation-btm{
		height: 68px;
	}
	.bottom-navigation .navigation-btm .main-menu-nav{
		position: fixed;
	    left: -320px;
	    background: #ffff;
	    width: 320px;
	    top: 0;
	    height: 100vh;
	    transition: 0.5s all;
	    overflow: auto;
	    z-index: 100;
	}
	.bottom-navigation .navigation-btm .main-menu-nav.active{
		left: 0;
	}
	.bottom-navigation .navigation-btm .main-menu-nav>nav>ul{
		display: block;
	}
	.bottom-navigation .navigation-btm .main-menu-nav>nav>ul>.menu-item{
		border-bottom: solid 2px #9e0000;
	}
	.bottom-navigation .navigation-btm .main-menu-nav>nav>ul>.menu-item>a{
		height: auto;
		padding: 6px 15px;
	}
	.bottom-navigation .navigation-btm .main-menu-nav>nav>ul>.menu-item.active>a:before,
	.bottom-navigation .navigation-btm .main-menu-nav>nav>ul>.menu-item.active>a:after{
		display: none;
	}
	.bottom-navigation .menu-item-has-children>.sub-menu{
		border-top: 1px solid #eee;
	    opacity: 1;
	    visibility: visible;
	    z-index: 999;
	    transform: translateY(0px);
	    transition: 0.3s;
	    position: relative;
	    width: 100%;
	    display: none;
	}
	.bottom-navigation .menu-item-has-children>.sub-menu>li.menu-item-has-children>a>.arrow{
		transform: none;
	}
	.bottom-navigation .menu-item-has-children>.sub-menu>li.menu-item-has-children>.sub-menu{
		left: 0;
		bottom: 0;
		top: 0;
	}
	/*subheader*/
	.subheader{
		padding: 140px 0 100px
	}
	.subheader.style-3, .subheader.blog-details {
		padding: 300px 0 250px;
	}
	.subheader.style-3 .subheader-bottom, 
	.subheader.blog-details .subheader-bottom{
		display: block;
	}
	.subheader .subheader-bottom .post-author{
		position: absolute;
	    bottom: 0;
	    right: 15px;
	}
	.subheader .subheader-bottom .post-details{
		margin-bottom: 20px;
		margin-right: 0;
		margin-left: 0;
	}
	.subheader .subheader-bottom .post-details>li{
		margin: 0 5px;
	}
	.subheader .subheader-bottom .post-details>li:after{
		margin-left: 4px;
	}
	.subheader.style-3 .subheader-bottom:after,
	.subheader.blog-details .subheader-bottom:after{
		display: none;
	}
	.blog-detail .comment-respond{
		padding: 40px;
	}
	.rs-menu-style .rs-menu-list{
		display: block;
	}
	.rs-menu-style.small-menu .rs-menu-list .menu-pro,
	.rs-menu-style .rs-menu-list .menu-pro{
		width: 100%;
	}
	.rs-menu-style.small-menu .rs-menu-list .menu-pro-right,
	.rs-menu-style .rs-menu-list .menu-pro-right{
		width: 100%;
		height: 150px;
		position: relative;
	}
	.rs-menu-style .rs-menu-list .menu-pro-right:before{
		position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    content: "";
	    height: 1px;
	    background: #3d322c;
	    opacity: .2
	}
	.rs-retailers ul li,
	.team-sec ul>.team-item,
	.rs-menu-style .rs-menu-style-2 ul li{
		width: 50%;
	}
	.contact-us .map-canvas{
		height: 400px;
	}
	.history-box.left-side{
		text-align: left;
	}
	.history-box.left-side .history-header{
		flex-flow: row-reverse;
	}
	.two-side-fix .aboutsec{
		padding: 80px 40px;
	}
	/*Gallery*/
	.gallery-box{
		border-bottom: 1px solid #3d322c;
	}
	.gallery-box:last-child{
		border-bottom: 0px solid #3d322c;
	}
	.gallery-box .gallery-item{
		width: 50%;
		min-height: 300px;
		height: 100%;
	}
	.gallery-box .gallery-item.left-hover, .gallery-box .gallery-item.right-hover{
		display: none;
	}
	.offer-strip{
		text-align: center;
	}
	
	.aboutsec .about-left .about-img {
		max-width: 550px;
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}
	.aboutsec .about-left{
		margin-bottom: 160px;
	}
	.aboutsec .about-left .about-img .about-img-second{
		left: 0;
		width: calc(100% - 80px);
		height: 100%;
		object-fit: cover;
	}
	.aboutsec .about-left .about-img .about-img-third{
		width: calc(100% - 80px);
	    height: 100%;
	    right: 0px;
		top: 80px!important;
		object-fit: cover;
	}
	.aboutsec.style-2 .about-left{
		margin-bottom: 80px;
	}
	.aboutsec.style-2 .about-left .about-img{
		left: 0;
	}
	/*Newpage*/
	.ingredients-2 .mobile-wrap .img-box{
		position: initial;
		padding-top: 80px;
	}
	.what-you-get .get-wrap{
		position: initial;
	}
	.aboutsec.homepage-4 .about-imgs,
	.accordion-2 .mobile-img{
		margin-left: auto;
		margin-right: auto;
	}
	.step-sec-1,
	.step-sec-2{
		height: auto;
	}
	.accordion-2 .mobile-img,
	.aboutsec.homepage-4 .about-imgs{
		max-width: 500px;
		padding: 0 15px;
	}
	.aboutsec.homepage-4 .about-right{
		padding: 0 15px;
	}
	.what-you-get .get-wrap,
	.ingredients-2 .mobile-wrap .img-box,
	.parallax-img-sec .parallax-imgs>.parallax-img-box{
		transform: none!important;
	}
	.parallax-img-sec .parallax-imgs{
		flex-wrap: wrap;
	}
	.parallax-img-sec{
		margin-top: 80px;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box{
		width: 33.33%;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box{
		top: 0;
		margin-bottom: 180px;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box:last-child{
		margin-bottom: 100px;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box>img{
		max-width: 200px;
	}
	.mb-md-20 {
		margin-bottom: 20px;
	}
	.mb-md-30 {
		margin-bottom: 30px;
	}
	.mb-md-40 {
		margin-bottom: 40px;
	}
	.mb-md-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 767px) {
	/*About Us*/
	.about-us-style .about-left-side .about-desc .about-box.img, 
	.about-us-style .about-right-side{
	    height: auto;
	}
	.about-us-style .about-left-side .about-desc .about-box {
	    padding-right: 0%;
	}
	.about-us-style .about-left-side .about-desc .about-box.img:before {
	    width: calc(100% - 30px);
	}
	.subheader .floating-social,
	.pagination-wrap .pagination-right,
	.rs-menu-style .menu-heading .btn-wrp,
	.recipe-sec .hiderecipe,
	.subheader .subheader-bottom .breadcrumb-right.post-category,
	.subheader .subheader-bottom .breadcrumb-right.socialize{
		display: none;
	}
	.rs-retailers ul li,
	.subheader .subheader-bottom .post-categories{
		width: 100%;
	}
	.overlap-menu{
		padding: 40px;
	}
	.reservation-page ul{
		flex-wrap: wrap;
	}
	.reservation-page ul>li{
		width: 50%;
		margin-bottom: 30px;
	}
	.reservation-page ul>li:nth-child(2):after{
		display: none;
	}
	.theme-tabs .nav-tabs{
		justify-content: center;
	}
	.recipe-sec .row>div:nth-child(1){
		order: 2;
	}
	.recipe-sec .row>div:nth-child(2){
		order: 1;
	}
	.recipe-sec .row>div:nth-child(3){
		order: 3;
	}
	
	.what-you-get .get-wrap .image-wrap{
		height: auto;
	}
	.tf-products-tabs .tabs>.nav-tabs>.nav-item{
		max-width: 50%;
	}
	.step-sec-2 .step-mobile-img{
		margin-top: 0;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box {
	    width: 50%;
	    max-width: 50%;
	}
	.mb-sm-20 {
		margin-bottom: 20px;
	}
	.mb-sm-30 {
		margin-bottom: 30px;
	}
	.mb-sm-40 {
		margin-bottom: 40px;
	}
	.mb-sm-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 576px) {
	.mobile-call-btn, .mobile-hamburger{
		width: 60px;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li{
		height: 48px;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li>a{
		font-size: 14px;
		padding-left: 15px;
	}
	.navigation-main .nav-div .nav-content .navigation-main-inner .main-menu-desktop>ul>li.active>a:after {
	    margin: 5px auto 0 15px;
	    bottom: 8px;
	}
	.footer-2 .copyright .ft-logo,
	.footer .footer-left-side .copyright .ft-logo{
		text-align: left;
	}
	.subheader .subheader-bottom .post-author{
		display: none;
	}
	.subheader .subheader-bottom .post-details>li {
	    margin: 0 15px;
	}
	.subheader .subheader-bottom .post-details>li:after {
	    margin-left: 13px;
	}
	.subheader.style-3 .subheader-bottom .post-categories>li, 
	.subheader.blog-details .subheader-bottom .post-categories>li{
		border: 1px solid rgba(61,50,44,.1);
	}
	.subheader.style-3 .subheader-bottom .post-categories, 
	.subheader.blog-details .subheader-bottom .post-categories{
		justify-content: space-between;
	}
	.blog-detail .post-details-tags-social .tags-box,
	.blog-detail .social-media-box>ul,
	.subheader .subheader-bottom .post-details{
		justify-content: center;
	}
	.rs-menu-style.small-menu .rs-menu-list .menu-pro-right,
	.rs-menu-style .rs-menu-list .menu-pro-right{
		flex-wrap: wrap;
		height: auto;
	}
	.rs-menu-style .rs-menu-list .pro-price, .rs-menu-style .rs-menu-list .pro-icon{
		width: 50%;
		height: 150px;
	}
	.rs-menu-style .rs-menu-list .pro-cart{
		padding-right: 5px;
		padding-top: 0;
	}
	.rs-menu-style .rs-menu-list .menu-pro-right:after {
	    position: absolute;
	    top: 0;
	    right: 0;
	    content: "";
	    width: 1px;
	    height: 100%;
	    background: #3d322c;
	    opacity: .2;
	}
	.team-sec ul>.team-item,
	.rs-menu-style .rs-menu-style-2 ul li{
		width: 100%;
	}

	.two-side-fix:after{
		display: none;
	}
	.two-side-fix:before{
		position: relative;
	    height: 700px;
	    width: 100%;
	    background-attachment: local;
	    background-size: cover;
	    background-position: top center;
	}
	.two-side-fix .aboutsec{
		padding: 80px 15px;
	}
	.two-side-fix .aboutsec .about-right .about-desc ul>li{
		width: 100%;
	}
	.gallery-box .gallery-item{
		width: 100%;
	}
	.gallery-box .gallery-item.bg-light-yellow:before{
		top: 0;
		left:50%;
		transform: translateX(-50%) rotate(180deg);
	}
	.recipe-sec .recipe-text,
	.recipe-sec .recipe-text.right-text{
		text-align: center;
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	.aboutsec .about-left{
		margin-bottom: 120px;
	}
	.aboutsec .about-left .about-img .about-img-second{
		width: calc(100% - 40px);
	}
	.aboutsec .about-left .about-img .about-img-third{
		width: calc(100% - 40px);
		top: 40px!important;
	}
	.aboutsec.style-2 .about-left{
		margin-bottom: 40px;
	}
	.what-you-get .get-wrap p{
		position: relative;
		top: 0;
	}
	.tf-products-tabs .tabs>.nav-tabs>.nav-item{
		max-width: 100%;
	}
	.parallax-img-sec .parallax-imgs>.parallax-img-box {
	    width: 100%;
	    max-width: 100%;
	}
	.tf-products-tabs .tab-content-sec .ingredients-item ul>li,
	.mb-xs-20 {
		margin-bottom: 20px;
	}
	.mb-xs-30 {
		margin-bottom: 30px;
	}
	.mb-xs-40 {
		margin-bottom: 40px;
	}
	.mb-xs-80 {
		margin-bottom: 80px;
	}
}
@media (max-width:460px){
	.comment-box .children{
		padding-left: 0;
	}
	.our_articles .post>.post-wrapper .post-meta,
	.overlap-menu,
	.blog-detail .comment-respond{
		padding: 20px;
	}
	.our_articles .post>.post-wrapper>.post-img{
		padding: 0;
		height: auto;
	}
	.reservation-page ul>li{
		width: 100%;
		max-width: 100%;
	}
	.reservation-page ul>li:after{
		display: none;
	}
	.ingredients-2 .mobile-wrap .img-box{
		max-width: 220px;
	}
	.ingredients-collapse .card .card-header .card-link{
		padding: 15px 30px;
	}
	.ingredients-collapse .card .card-header .card-link:after{
		right: 30px;
	}
	.accordion-2 .accordion-2-collapse .card .card-body,
	.ingredients-collapse .card .card-body{
		padding-left: 45px;
	}
	.accordion-2 .accordion-2-collapse .card .card-header .card-link{
		font-size: 18px;
	}
	.accordion-2 .accordion-2-collapse .card .card-header .card-link span{
		width: 20px;
		margin-right: 20px;
	}
	.accordion-2 .accordion-2-collapse .card .card-header .card-link:before{
		width: calc(100% - 40px);
	}

}