	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/01/2020 09:06
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
}

.flaticon-bed:before { content: "\f100"; }
.flaticon-bath:before { content: "\f101"; }
.flaticon-bathtub:before { content: "\f102"; }
.flaticon-relax:before { content: "\f103"; }
.flaticon-3d-design:before { content: "\f104"; }
.flaticon-stretching:before { content: "\f105"; }
.flaticon-maximinize:before { content: "\f106"; }
.flaticon-scale:before { content: "\f107"; }
.flaticon-garage:before { content: "\f108"; }
.flaticon-parking-sign:before { content: "\f109"; }
.flaticon-garage-1:before { content: "\f10a"; }
.flaticon-car:before { content: "\f10b"; }
.flaticon-car-1:before { content: "\f10c"; }
.flaticon-photo-camera:before { content: "\f10d"; }
.flaticon-picture:before { content: "\f10e"; }
.flaticon-bookmark:before { content: "\f10f"; }
.flaticon-star:before { content: "\f110"; }
.flaticon-star-1:before { content: "\f111"; }
.flaticon-360-degrees:before { content: "\f112"; }
.flaticon-camera:before { content: "\f113"; }
.flaticon-multimedia:before { content: "\f114"; }
.flaticon-play:before { content: "\f115"; }
.flaticon-back:before { content: "\f116"; }
.flaticon-placeholder:before { content: "\f117"; }
.flaticon-office-building:before { content: "\f118"; }
.flaticon-skyline:before { content: "\f119"; }
.flaticon-house:before { content: "\f11a"; }
.flaticon-architecture-and-city:before { content: "\f11b"; }
.flaticon-discount:before { content: "\f11c"; }
.flaticon-percent:before { content: "\f11d"; }
.flaticon-sale-tag:before { content: "\f11e"; }
.flaticon-tag:before { content: "\f11f"; }
.flaticon-house-1:before { content: "\f120"; }
.flaticon-architecture-and-city-1:before { content: "\f121"; }
.flaticon-house-2:before { content: "\f122"; }
.flaticon-agent:before { content: "\f123"; }
.flaticon-call-center:before { content: "\f124"; }
.flaticon-detective:before { content: "\f125"; }
.flaticon-employee:before { content: "\f126"; }
.flaticon-reuse:before { content: "\f127"; }
.flaticon-updated:before { content: "\f128"; }
.flaticon-arrow:before { content: "\f129"; }
.flaticon-size:before { content: "\f12a"; }
.flaticon-power:before { content: "\f12b"; }
.flaticon-gps:before { content: "\f12c"; }
.flaticon-price:before { content: "\f12d"; }
.flaticon-tag-1:before { content: "\f12e"; }
.flaticon-plans:before { content: "\f12f"; }
.flaticon-door:before { content: "\f130"; }
.flaticon-living-room:before { content: "\f131"; }
.flaticon-fireplace:before { content: "\f132"; }
.flaticon-twin:before { content: "\f133"; }
.flaticon-fireplace-1:before { content: "\f134"; }
.flaticon-antique-balcony:before { content: "\f135"; }
.flaticon-window:before { content: "\f136"; }
.flaticon-deck-chair:before { content: "\f137"; }
.flaticon-deck-chair-1:before { content: "\f138"; }
.flaticon-beach-chair:before { content: "\f139"; }
.flaticon-towel:before { content: "\f13a"; }
.flaticon-dryer:before { content: "\f13b"; }
.flaticon-laundry:before { content: "\f13c"; }
.flaticon-kitchen-tools:before { content: "\f13d"; }
.flaticon-toilet:before { content: "\f13e"; }
.flaticon-sink:before { content: "\f13f"; }
.flaticon-bathtub-1:before { content: "\f140"; }
.flaticon-shower:before { content: "\f141"; }
.flaticon-furniture:before { content: "\f142"; }
.flaticon-sit-down:before { content: "\f143"; }
.flaticon-sofa:before { content: "\f144"; }
.flaticon-radiator:before { content: "\f145"; }
.flaticon-heater:before { content: "\f146"; }
.flaticon-fan:before { content: "\f147"; }
.flaticon-viber:before { content: "\f148"; }
.flaticon-telephone:before { content: "\f149"; }
.flaticon-envelope:before { content: "\f14a"; }
.flaticon-email:before { content: "\f14b"; }
.flaticon-call:before { content: "\f14c"; }
.flaticon-telephone-1:before { content: "\f14d"; }
.flaticon-house-3:before { content: "\f14e"; }
.flaticon-balcony:before { content: "\f14f"; }
.flaticon-swimming-pool:before { content: "\f150"; }
.flaticon-house-4:before { content: "\f151"; }
.flaticon-elevator:before { content: "\f152"; }
.flaticon-house-5:before { content: "\f153"; }
.flaticon-sale:before { content: "\f154"; }
.flaticon-bed-1:before { content: "\f155"; }
.flaticon-bed-2:before { content: "\f156"; }
.flaticon-bathtub-2:before { content: "\f157"; }
.flaticon-bathtub-3:before { content: "\f158"; }
.flaticon-bath-1:before { content: "\f159"; }
.flaticon-car-in-garage:before { content: "\f15a"; }
.flaticon-parking:before { content: "\f15b"; }
.flaticon-car-inside-a-garage:before { content: "\f15c"; }
.flaticon-selection:before { content: "\f15d"; }
.flaticon-shape:before { content: "\f15e"; }
.flaticon-selection-1:before { content: "\f15f"; }
.flaticon-signaling:before { content: "\f160"; }
.flaticon-area:before { content: "\f161"; }
.flaticon-right-arrow:before { content: "\f162"; }
.flaticon-next:before { content: "\f163"; }
.flaticon-left-arrow:before { content: "\f164"; }
.flaticon-back-1:before { content: "\f165"; }
.flaticon-arrows:before { content: "\f166"; }
.flaticon-magnifying-glass:before { content: "\f167"; }
.flaticon-view:before { content: "\f168"; }
.flaticon-user:before { content: "\f169"; }
.flaticon-like:before { content: "\f16a"; }
.flaticon-chat:before { content: "\f16b"; }
.flaticon-pin:before { content: "\f16c"; }
.flaticon-mail:before { content: "\f16d"; }
.flaticon-bell:before { content: "\f16e"; }
.flaticon-tick:before { content: "\f16f"; }
.flaticon-mail-1:before { content: "\f170"; }
.flaticon-search:before { content: "\f171"; }
.flaticon-comment:before { content: "\f172"; }
.flaticon-price-1:before { content: "\f173"; }
.flaticon-money:before { content: "\f174"; }
.flaticon-money-1:before { content: "\f175"; }
.flaticon-heart:before { content: "\f176"; }
.flaticon-price-2:before { content: "\f177"; }
.flaticon-expand:before { content: "\f178"; }
.flaticon-resize:before { content: "\f179"; }
.flaticon-minimize:before { content: "\f17a"; }
.flaticon-right-arrow-1:before { content: "\f17b"; }
.flaticon-down-arrow:before { content: "\f17c"; }
.flaticon-refresh:before { content: "\f17d"; }
.flaticon-right-arrow-2:before { content: "\f17e"; }
.flaticon-left-arrow-1:before { content: "\f17f"; }
.flaticon-spin:before { content: "\f180"; }
.flaticon-diagonal:before { content: "\f181"; }
.flaticon-minimize-1:before { content: "\f182"; }
.flaticon-up-arrow:before { content: "\f183"; }
.flaticon-diagonal-1:before { content: "\f184"; }
.flaticon-diagonal-arrow:before { content: "\f185"; }
.flaticon-horizontal-arrows:before { content: "\f186"; }
.flaticon-compress:before { content: "\f187"; }
.flaticon-up-arrow-1:before { content: "\f188"; }
.flaticon-transfer:before { content: "\f189"; }
.flaticon-refresh-1:before { content: "\f18a"; }
.flaticon-arrow-1:before { content: "\f18b"; }
.flaticon-left:before { content: "\f18c"; }
.flaticon-double-arrow:before { content: "\f18d"; }
.flaticon-expand-1:before { content: "\f18e"; }