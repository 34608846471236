:focus {
  outline: none;
}
.slick-dots{
  position: relative;
  bottom: 0;
}
.slick-dots li{
  width: auto;
  height: auto;
}
.slick-dots li button{
  padding: 0;
}
.slick-dots li button:before {
display: none;
}
.slick-dotted.slick-slider{
  margin-bottom: 0;
}
.flip-footer .footer > .container-fluid > .row{
  flex-direction: row-reverse;
}
a.add-to-cart{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.our_articles .post>.post-wrapper .post-meta .post-data .post-categories>a + a{
  margin-left: 8px;
}
.testimonial-slider-single .slick-track .slide-item:nth-child(odd){
  background: #9e0000;
}
.testimonial-slider-single .slick-track .slide-item:nth-child(even){
  background: #25292e;
}
.parallax-img-sec .parallax-imgs .image-2{
  transform: translate3d(0px, -126.6px, 0px);
}
.parallax-img-sec .parallax-imgs .image-3{
  transform: translate3d(0px, -253.2px, 0px);
}
.react-datepicker-popper {
  z-index: 100;
}
.accordion .card .card-header{
  display: flex;
  flex-basis: 100%;
  order: 1;
}
.accordion .card .collapseparent{
  order: 2;
}
.ingredients-collapse .card .card-header .btn-link {
  background-color: transparent;
  padding: 15px 0px;
  color: #3d322c;
  border-radius: 0;
}
.ingredients-collapse .card .card-header .btn-link:after {
  right: 15px;
  transform: translateY(-50%) rotate(
-90deg
);
}
.ingredients-collapse .card .collapseparent.show+.card-header .btn-link:after{
  transform: translateY(-50%);
  right: 45px;
}
.ingredients-collapse .card .collapseparent.show+.card-header .btn-link {
  color: #fff;
  padding: 15px 45px;
  position: relative;
  background-color: rgba(158, 0, 0, 0.81);
}
.accordion-2 .accordion-2-collapse .card .card-header .btn-link:after {
  transform: translateY(-50%);
}
.accordion-2 .accordion-2-collapse .card .collapseparent.show+.card-header .btn-link:after {
  transform: translateY(-50%) rotate(
    -180deg
    );
}
@media (min-width: 992px){
  .our-history-sec .row:nth-child(even){
      justify-content: flex-end;
  }
  .our-history-sec .row:nth-child(even) .history-box.left-side .history-header{
      flex-direction: row-reverse;
  }
  .our-history-sec .row:nth-child(even) .history-box.left-side{
      text-align: left;
  }
}
.subheader .page-breadcrumb>ul>li.active{
  color: #fff;
}
.subheader .page-breadcrumb>ul>li:after{
  margin-left: 10px;
}
.video-btn-wrapper:before{
  z-index: -1;
}
.comment-box .comments li article .comment-avatar{
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{
 z-index: -1;
}
.subheader .page-title>h1{
    word-break: break-all;
}
.subheader .page-breadcrumb>ul{
    text-align: center;
}
@media (max-width:991px) {
    .subheader .page-title>h1{
        font-size: 90px;
    }
}
@media (max-width:767px) {
    .subheader .page-title>h1{
        font-size: 60px;
    }
}
@media (max-width:576px) {
    .subheader .page-title>h1{
        font-size: 40px;
    }
}